import React, {useState} from 'react';
import {Modal, Box, Slider, TextField, Button, Typography} from '@mui/material';
import {Simulation} from '../types/Simulation';
import {useKeycloak} from "@react-keycloak/web";


interface Props {
    open: boolean;
    onClose: () => void;
    simulation: Simulation;
    onUpdate: (updatedConfig: any) => void;
}

const EditSimulationModal: React.FC<Props> = ({open, onClose, simulation, onUpdate}) => {
    const [intervalSim, setIntervalSim] = useState(simulation.configuration.interval_sim);
    const {keycloak} = useKeycloak();
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setIntervalSim(newValue as number);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(200, Math.min(3000, Number(e.target.value)));
        setIntervalSim(value);
    };

    const handleSubmit = async () => {
        const updatedConfig = {
            ...simulation.configuration,
            interval_sim: intervalSim,
        };

        try {
            const response = await fetch(`/api/v1/simulations/${simulation.id}/configuration/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${keycloak.token}`,
                },
                body: JSON.stringify(updatedConfig),
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
                return;
            }

            const newConfiguration = await response.json();
            onUpdate(newConfiguration);
            onClose();
        } catch (error) {
            console.error('Error updating simulation configuration:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    p: 4,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 3,
                    maxWidth: 400,
                    margin: 'auto',
                    mt: '10%',
                }}
            >
                <Typography variant="h6" gutterBottom>Edit Simulation</Typography>
                <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                    <TextField
                        label="Simulation Interval (ms)"
                        type="number"
                        value={intervalSim}
                        onChange={handleInputChange}
                        sx={{mr: 2}}
                    />
                    <Slider
                        value={intervalSim}
                        min={200}
                        max={3000}
                        step={25}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        sx={{flexGrow: 1}}
                    />
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button onClick={handleSubmit} color="primary" variant="contained" sx={{mr: 1}}>Update</Button>
                    <Button onClick={onClose} color="secondary" variant="outlined">Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditSimulationModal;
