import {ListItem, ListItemText, IconButton, Box} from '@mui/material';
import {Pause, PlayArrow, Edit, FlightLand, Delete} from '@mui/icons-material';
import {Simulation} from '../types/Simulation';
import {useState} from 'react';
import EditSimulationModal from './EditSimulationModal';
import {useKeycloak} from "@react-keycloak/web";


interface Props {
    simulation: Simulation;
    setSimulations: (updater: (prevSimulations: Simulation[]) => Simulation[]) => void;
}

export default function SimulationItem({simulation, setSimulations}: Props) {
    const [modalOpen, setModalOpen] = useState(false);
    const {keycloak} = useKeycloak();
    const handlePause = async () => {
        const updatedConfiguration = {
            ...simulation.configuration,
            paused: !simulation.configuration.paused,
        };

        try {
            const response = await fetch(`/api/v1/simulations/${simulation.id}/configuration/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${keycloak.token}`,
                },
                body: JSON.stringify(updatedConfiguration),
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
                return;
            }

            const newConfiguration = await response.json();

            const updatedSimulation: Simulation = {
                ...simulation,
                configuration: newConfiguration,
            };

            setSimulations(prevSimulations =>
                prevSimulations.map(sim =>
                    sim.id === simulation.id ? updatedSimulation : sim
                )
            );
        } catch (error) {
            console.error('Error pausing simulation:', error);
        }
    };

    const handleEdit = () => {
        setModalOpen(true);
    };

    const handleLand = async () => {
        try {
            const response = await fetch(`/api/v1/simulations/${simulation.id}/?kill=false`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
                return;
            }
            setSimulations(prev => prev.filter(sim => sim.id !== simulation.id));
        } catch (error) {
            console.error('Error landing simulation:', error);
        }
    };

    const handleKill = async () => {
        try {
            const response = await fetch(`/api/v1/simulations/${simulation.id}/?kill=true`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            });

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status}`);
                return;
            }
            setSimulations(prev => prev.filter(sim => sim.id !== simulation.id));
        } catch (error) {
            console.error('Error killing simulation:', error);
        }
    };

    const handleUpdate = (updatedConfig: any) => {
        setSimulations(prevSimulations =>
            prevSimulations.map(sim =>
                sim.id === simulation.id ? {...sim, configuration: updatedConfig} : sim
            )
        );
    };

    return (
        <>
            <ListItem
                sx={{
                    border: '1px solid #ccc',
                    borderRadius: 1,
                    padding: 1,
                    marginBottom: 1
                }}
            >
                <Box>
                    <ListItemText primary={simulation.configuration.uas_id}/>
                    <Box sx={{display: 'flex', gap: 1, mt: 1}}>
                        <IconButton onClick={handlePause} color="primary">
                            {simulation.configuration.paused ? <PlayArrow/> : <Pause/>}
                        </IconButton>
                        <IconButton onClick={handleEdit} color="secondary">
                            <Edit/>
                        </IconButton>
                        <IconButton onClick={handleLand} color="success">
                            <FlightLand/>
                        </IconButton>
                        <IconButton onClick={handleKill} color="error">
                            <Delete/>
                        </IconButton>
                    </Box>
                </Box>
            </ListItem>

            <EditSimulationModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                simulation={simulation}
                onUpdate={handleUpdate}
            />
        </>
    );
}
