import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Map from './components/Map';
import { useKeycloak } from '@react-keycloak/web';

const App = () => {
    const { keycloak } = useKeycloak();

    return (
        <div>
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 2,
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Dronetag Mocker
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Click on an empty place on the map to create a new simulation
                        </Typography>
                    </Box>
                    {!keycloak.authenticated ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => keycloak.login()}
                        >
                            Login
                        </Button>
                    ) : (
                        <Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => keycloak.logout()}
                            >
                                Logout
                            </Button>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                Logged in as: {keycloak.tokenParsed?.preferred_username || keycloak.tokenParsed?.email}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Map />
                </Box>
            </Box>
        </div>
    );
};

export default App;
