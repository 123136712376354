import { styled, useTheme } from '@mui/material'

interface Props {
  selected: boolean
}

const Root = styled('div')(`
    width: 32px;
    height: 32px;
    position: relative;
  `)

const ColorPolygon = styled('polygon')<{ color: string; width: number }>(
  ({ color, width }) => `
    fill: ${color};
    stroke: ${color};
    stroke-width: ${width};
    box-shadow: 0px 2px 5px #000000cc;
  `
)

const ShadowPolygon = styled('polygon')(
  ({ theme }) => `
    filter: url('#shadowBlur');
    fill: ${theme.palette.primary.dark};
  `
)

export default function AircraftIcon({ selected }: Props) {
  const theme = useTheme()
  return (
    <Root>
      <svg width="32" height="32" viewBox="-8 -8 32 32">
        <defs>
          <filter id="shadowBlur" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
          </filter>
        </defs>
        <ShadowPolygon strokeLinejoin="round" points="8,14 0,0 16,0" />
        <ColorPolygon
          width={8}
          color={theme.palette.background.paper}
          strokeLinejoin="round"
          points="8,14 0,0 16,0"
        />
        <ColorPolygon
          color={
            selected ? theme.palette.success.light : theme.palette.primary.dark
          }
          width={2}
          strokeLinejoin="round"
          points="8,11 3,2 13,2"
        />
      </svg>
    </Root>
  )
}
