import { List } from '@mui/material';
import SimulationItem from './SimulationItem';
import { Simulation } from '../types/Simulation';

interface Props {
    simulations: Simulation[];
    setSimulations: (updater: (prevSimulations: Simulation[]) => Simulation[]) => void;
}

export default function SimulationList({ simulations, setSimulations }: Props) {
    return (
        <List>
            {simulations.map((simulation) => (
                <SimulationItem key={simulation.id} simulation={simulation} setSimulations={setSimulations} />
            ))}
        </List>
    );
}
